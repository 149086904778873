import React, { useState, useEffect } from 'react';
import './ZenroNavagatorBar.css';
import HistoryIcon from '../assets/zenroIcons/HistoryIcon.png';
import MessageIcon from '../assets/zenroIcons/MessageIcon.png';
import OverviewIcon from '../assets/zenroIcons/OverviewIcon.png';
import RecommendationsIcon from '../assets/zenroIcons/RecommendationsIcon.png';
import ZenroAppIcon from '../assets/zenroIcons/ZenroAppIcon.png';


const ZenroNavagatorBar = ({ onMenuItemSelect, userInfo}) => {

    const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
    const [pickName, setPickName] = useState(false);
    const handleMenuItemClick = (menuItem) => {
        setSelectedMenuItem(menuItem);
        onMenuItemSelect(menuItem); 
    };

    return (
        <div className="ZenroNavagatorBar">
            <img src={ZenroAppIcon} alt="shot1" className="ZenroAppIcon" />
            <div className='ZenroNavagatorBar-line1' />
            <ul className='ZenroNavagatorBar-ul'>
                <li className={`ZenroNavagatorBar-options overview ${selectedMenuItem === 'overview' ? 'active' : ''}`} 
                    onClick={() => handleMenuItemClick('overview')}>
                    <span className='ZenroNavagatorBar-option1'>
                        <img src={OverviewIcon} alt="shot1" className="OverviewIcon" />
                        <h1 className='ZenroNavagatorBar-h1'>Overview</h1>
                    </span>
                </li>
                <li className={`ZenroNavagatorBar-options history ${selectedMenuItem === 'history' ? 'active' : ''}`} 
                    onClick={() => handleMenuItemClick('history')}>
                    <span className='ZenroNavagatorBar-option2'>
                        <img src={HistoryIcon} alt="shot1" className="HistoryIcon" />
                        <h1 className='ZenroNavagatorBar-h1'>History</h1>
                    </span>
                </li>
                <li className={`ZenroNavagatorBar-options recommendation ${selectedMenuItem === 'recommendation' ? 'active' : ''}`} 
                    onClick={() => handleMenuItemClick('recommendation')}>
                    <span className='ZenroNavagatorBar-option3'>
                        <img src={RecommendationsIcon} alt="shot1" className="RecommendationsIcon" />
                        <h1 className='ZenroNavagatorBar-h1'>Recommendations</h1>
                    </span>
                </li>
                <li className={`ZenroNavagatorBar-options account ${selectedMenuItem === 'account' ? 'active' : ''}`} 
                    onClick={() => handleMenuItemClick('account')}>
                    <span className='ZenroNavagatorBar-option5'>
                        <h2 className='ZenroNavagatorBar-h2-name'>{userInfo.FirstName+' '+userInfo.LastName}</h2>
                        <h2 className='ZenroNavagatorBar-h2-email'>{userInfo.Email}</h2>
                    </span>
                </li>
                {/* <span className='ZenroNavagatorBar-option4'>
                        <img src={MessageIcon} alt="shot1" className="MessageIcon" />
                        <h1 className='ZenroNavagatorBar-h1'>Message</h1>
                    </span> */}
            </ul>
        </div >
    );
};

export default ZenroNavagatorBar;
