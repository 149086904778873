import React from 'react';
import './AgriloRecentTestBox.css';

const AgriloRecentTestBox = ({ test }) => {
    const testColor = ['#CFBF05', '#4C6CFD', '#C83A31']
    const recommendation = ['Low', 'Optimal', 'High']
    return (
        <div className="AgriloRecentTestBox">
            <h1 className='AgriloRecentTestBox-h1'>{test.Name} | {new Date(test.Date).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })} </h1>
            <div className='AgriloRecentTestBox-status' style={{backgroundColor:testColor[test.rating]}}>
                <h4 className='AgriloRecentTestBox-h4'>{recommendation[test.rating]}</h4>
            </div>
            <div className='AgriloRecentTestBox-type1'>
                <h5 className='AgriloRecentTestBox-h5'>Nitrate</h5>
            </div>
            <div className='AgriloRecentTestBox-type2'>
                <h5 className='AgriloRecentTestBox-h5'>Barley</h5>
            </div>
            <h2 className='AgriloRecentTestBox-h2' style={{color:testColor[test.rating]}}>{test.Result}</h2>
            <h3 className='AgriloRecentTestBox-h3'>ppm</h3>
        </div>
    );
};

export default AgriloRecentTestBox;
