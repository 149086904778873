import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import SuccessPage from './pages/SuccessPage';
import ContactPage from './pages/ContactPage';
import Agrilo from './agrilo_pages/Agrilo';
import Zenro from './zenro_pages/Zenro';
import ComingSoonPage from './pages/ComingSoonPage'
import ResetEmailPage from './pages/ResetEmailPage'
import ResetPasswordPage from './pages/ResetPasswordPage';
import ActivateAccountPage from './pages/ActivateAccountPage'
import ActivateTokenPage from './pages/ActivateTokenPage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import CookiePolicyPage from './pages/CookiePolicyPage'

const App = () => {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/home':
        document.title = 'Home';
        break;
      case '/login':
        document.title = 'Login';
        break;
      case '/sign-up':
        document.title = 'Sign Up';
        break;
      case '/success':
        document.title = 'Success';
        break;
      case '/contact-us':
        document.title = 'Contact Us';
        break;
      default:
        document.title = 'pimasens';
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/products" element={<ComingSoonPage />} />
      <Route path="/pricing" element={<ComingSoonPage />} />
      <Route path="/about-us" element={<ComingSoonPage />} />
      <Route path="/success" element={<ComingSoonPage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/Zenro" element={<Zenro/>} />
      <Route path="/send" element={<ResetEmailPage />} />
      <Route path="/reset/:token" element={<ResetPasswordPage />} />
      <Route path="/activate/:token" element={<ActivateAccountPage/>}/>
      <Route path="/authenticationCode" element={<ActivateTokenPage/>}/>
      <Route path="about/about-us" element={<ComingSoonPage/>}/>
      <Route path="about/privacy-policy" element={<PrivacyPolicyPage/>}/>
      <Route path="/about/cookie-policy" element={<CookiePolicyPage/>}/>
      <Route path="/Agrilo" element={<Agrilo/>}/>
    </Routes>
  );
};

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
