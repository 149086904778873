import React from 'react';
import './AnalytesSelector.css';

const AnalytesSelector = () => {
    return (
        <div className="AnalytesSelector">
            <h1 className='AnalytesSelector-header'>ANALYTES</h1>
            <div className='AnalytesSelector-option1'>
                <h1 className='AnalytesSelector-h2'>Cortisol</h1>
            </div>
            {/* <div className='AnalytesSelector-option2'>
                <h1 className='AnalytesSelector-h2 option2'>Cortisol</h1>
            </div> */}
        </div>
    );
};

export default AnalytesSelector;
