import './ResetPasswordPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Routes, Route, useParams } from 'react-router-dom';
import axios from 'axios';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [conPassword, setConPassword] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [reset, setReset] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConPasswordChange = (e) => {
    setConPassword(e.target.value);
  };

  const handleResetBTNClicked = async (e) => {
    if (password !== conPassword) {
      setErrorMessage("Passwords don't match!");
    } else {
      setErrorMessage('');
      e.preventDefault();
      let dataToSend = {};
      dataToSend = { Password: password, ResetToken: token }

      axios.put('https://www.pimasens.com/api/resetUserPassword/', dataToSend, {
      })
        .then(response => {
          const data = response.data;

          if (data.error) {
            setError('An error occurred');
            setShowForm(false)
          } else {
            setReset(true)
            setShowForm(false)
          }
        })
        .catch(error => {
          setError('An error occurred');
          setShowForm(false)
        });
    }
  };
  console.log(error);





  return (
    <div className='ResetPasswordPage'>
      <img src={LoginLogo} alt="LOGO" className="left-image" />
      <div className='ResetPasswordPage-form-box'>
        <h1 className='ResetPasswordPage-header'>Reset account password</h1>
        {errorMessage && (<div className="ResetPasswordPage-error">{errorMessage}</div>)}
        {showForm && (
          <form className='ResetPasswordPage-form'>
            <input className='ResetPasswordPage-password' type="password" id="Password" name="password" value={password} onChange={handlePasswordChange} placeholder="Password" />
            <input className='ResetPasswordPage-password-confirm' type="password" id="Reconfirm" name="reconfirm" value={conPassword} onChange={handleConPasswordChange} placeholder="Confirm password" />
            <input className='ResetPasswordPage-btn' type="button" value="Reset Password" onClick={handleResetBTNClicked} />
          </form>
        )}
        {error && (
          <h2 className='ResetPasswordPage-h2'>Reset Failed</h2>
        )}
        {reset && (
          <h2 className='ResetPasswordPage-h2'>Reset Success</h2>
        )}

        <img src={LoginSmallLogo} alt="LOGOSmall" className="ResetPasswordPage-image" />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
