import './ResetEmailPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ResetEmailPage = () => {
  const [showForm, setShowForm] = useState(true);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState()
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };



  const handleContinueBTNClicked = async (e) => {
    if (email != '') {
      const queryParams = {
        Email: email,
      };
      axios.get('https://www.pimasens.com/api/getResetToken/', {
        params: queryParams
      })
        .then(response => {
          const data = response.data;

          if (data.error) {
            setError('An error occurred');
          } else {
            setToken(data)
          }
        })
        .catch((error) => {
          setError('An error occurred');
        });
      setShowForm(false)
    }
  };



  return (
    <div className='ResetEmailPage'>
      <img src={LoginLogo} alt="LOGO" className="left-image" />
      <div className='ResetEmailPage-form-box'>
        <h1 className='ResetEmailPage-header'>Forgot Password</h1>
        {showForm && (
          <form className='ResetEmailPage-form'>
            <label className='ResetEmailPage-label' htmlFor="Email">Enter the email address associated with your account and we'll send you a link to reset your password</label>
            <input className='ResetEmailPage-input' type="email" id="Email" name="email" value={email} onChange={handleEmailChange} />
            <input className='ResetEmailPage-btn' type="button" value="Continue" onClick={handleContinueBTNClicked} />
          </form>
        )}
        {!showForm && (
          <h2 className='ResetEmailPage-h2'>If an account exists for {email}, you will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.</h2>
        )}
        <img src={LoginSmallLogo} alt="LOGOSmall" className="ResetEmailPage-image" />
      </div>
    </div>
  );
};

export default ResetEmailPage;
