import React, { useState } from 'react';
import './AgriloSummaryPage.css';
import AgriloCalendar from '../components/AgriloCalendar';
import AgriloTaskBox from '../components/AgriloTaskBox';
import AgriloPinnedBox from '../components/AgriloPinnedBox';
import AgriloTaskBar from '../components/AgriloTaskBar';

const AgriloSummaryPage = (access) => {
    const [task, setTask] = useState([
        {
            eventID: 1,
            Name: "Full Nutrient Ammendment",
            StartDate: "2024-10-01",
            EndDate: "2024-10-07",
            Color: "lightblue",
            Description: "Check and apply required nutrient amendments to the soil. Ensure all amendments are properly documented for future reference"
        },
        {
            eventID: 2,
            Name: "Nitrate Soil Test",
            StartDate: "2024-10-10",
            EndDate: "2024-10-15",
            Color: "orange",
            Description: "Fake stuff blah blahj"
        },
        {
            eventID: 3,
            Name: "Nutrient Ammendment",
            StartDate: "2024-10-18",
            EndDate: "2024-10-18",
            Color: "lightgreen",
            Description: "DIFERENT LENGAGGHGHG hijsdgnbkjan ijgajl agjkasnjkgnag "
        },
        {
            eventID: 4,
            Name: "Demostraction",
            StartDate: "2024-11-25",
            EndDate: "2024-12-05",
            Color: "pink",
            Description: "Different descriptions wow "
        },
        {
            eventID: 5,
            Name: "more dummy",
            StartDate: "2024-12-18",
            EndDate: "2024-12-25",
            Color: "lightblue",
            Description: "Different descriptions wow "
        },
    ]);
    const [filteredTasks, setFilteredTasks] = useState(task); // Initialize with all tasks

    const handleTaskFilter = (filtered) => {
        setFilteredTasks(filtered);
        console.log("WOWZER")
    };

    return (
        <div className="Agrilo-SummaryPage">
            <h1 className='Agrilo-SummaryPage-h1'> Summary</h1>
            <div className='Agrilo-SummaryPage-line1' />
            <AgriloTaskBar task={filteredTasks} />
            <AgriloCalendar task={task} onTaskFilter={handleTaskFilter} />
            <AgriloTaskBox task={filteredTasks} />
            <AgriloPinnedBox />
        </div>
    );
};

export default AgriloSummaryPage;