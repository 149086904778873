import React, { useState, useEffect } from 'react';
import './AgriloTaskBar.css';

const AgriloTaskBar = ({ task }) => {


    const DateConverter = (startDate, endDate) => {
        const start = new Date(startDate + 'T12:00:00Z');
        const end = new Date(endDate + 'T12:00:00Z');

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const startMonth = monthNames[start.getMonth()];
        const startDay = start.getDate();
        const endMonth = monthNames[end.getMonth()];
        const endDay = end.getDate();

        if (start.toDateString() === end.toDateString()) {
            return `${startMonth} ${startDay}`;
        } else if (startMonth === endMonth) {
            return `${startMonth} ${startDay}-${endDay}`;
        } else {
            return `${startMonth} ${startDay}-${endMonth} ${endDay}`;
        }
    }

    return (
        <div className="AgriloTaskBar">
            <div className='AgriloTaskBar-event-start'>
                <h1 className='AgriloTaskBar-event-text'>Event</h1>
            </div>
            <div className="AgriloTaskBar-item-container">
                <nav className="AgriloTaskBar-items">
                    <ul className='AgriloTaskBar-ul'>
                        {task.slice().map(task => (
                            <li
                                key={task.TaskID}
                                className='AgriloTaskBar-test-item'
                            >
                                <div className='AgriloTaskBar-eventbox'>
                                    <div className='AgriloTaskBar-task-color' style={{ backgroundColor: task.Color }} />
                                    <h1 className='AgriloTaskBar-task-name'>{task.Name}</h1>
                                    <h1 className='AgriloTaskBar-task-range'>{DateConverter(task.StartDate, task.EndDate)}</h1>
                                </div>
                                <div className='AgriloTaskBar-description'>
                                    <h1 className='AgriloTaskBar-description-header'>Description</h1>
                                    <h1 className='AgriloTaskBar-description-text'>{task.Description}</h1>
                                </div>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default AgriloTaskBar;