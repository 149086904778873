import React from 'react';
import './SensorSelector.css';


const SensorSelector = () => {
    return (
        <div className="SensorSelector">
            <h1 className='SensorSelector-header'>DEVICE</h1>
            <div className='SensorSelector-option1'>
                <h2 className='SensorSelector-h2'>PalmSens 4</h2>
            </div>
        </div>
    );
};

export default SensorSelector;
