import React, { useState, useEffect } from 'react';
import './OverviewPage.css';
import axios from 'axios';
import RecentTestBox from '../components/RecentTestBox';
import AverageTestBox from '../components/AverageTestBox';
import SetUpBox from '../components/SetUpBox';

const OverviewPage = (access) => {
  const [error, setError] = useState('');
  const [lastTwoResults, setLastTwoResults] = useState([]);
  const [average1, setAverage1] = useState([]);
  const [average2, setAverage2] = useState([]);

  // get current month
  const currentDate = new Date();
  const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
  const lastlastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2);



  useEffect(() => {
    axios.get('https://www.pimasens.com/api/getTest/', {
      headers: {
        'Authorization': 'Bearer ' + access.access
      }
    })
      .then(response => {
        const data = response.data;

        if (data.error === "No tests found") {
          setError('An error occurred');
        } else {
          const lastTwo = data.slice(-2);
          setLastTwoResults(lastTwo.reverse());
        }
      })
      .catch(error => {
        setError('An error occurred');
      });
  }, []);

  // AverageTest api call1
  useEffect(() => {
    const postData = {
      Year: lastlastMonth.getFullYear(),
      Month: lastlastMonth.getMonth() + 1,
      SensorID: 1
    };
    axios.post('https://www.pimasens.com/api/getAverageTest/', postData, {
      headers: {
        'Authorization': 'Bearer ' + access.access,        
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setAverage1({
          ...response.data, 
          month: lastlastMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
      });
      })
      .catch(error => {
        setAverage1('Error fetching new data');
        setAverage1({
          "average_test_result": 0,
          "test_count": 0,
          "highest_test_result": 0,
          "lowest_test_result": 0,
          "month": lastlastMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      });
  }, []);

  // AverageTest api call2 
  useEffect(() => {
    const postData = {
      Year: lastMonth.getFullYear(),
      Month: lastMonth.getMonth() + 1,
      SensorID: 1
    };
    axios.post('https://www.pimasens.com/api/getAverageTest/', postData, {
      headers: {
        'Authorization': 'Bearer ' + access.access,        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        setAverage2({
          ...response.data, 
          month: lastMonth.toLocaleString('default', { month: 'long' }).toUpperCase() 
      });
      })
      .catch(error => {
        setAverage2('Error fetching new data');
        setAverage2({
          "average_test_result": 0,
          "test_count": 0,
          "highest_test_result": 0,
          "lowest_test_result": 0,
          "month": lastMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      });
  }, []);


  return (
    <div className="OverView">
      <div className="AverageTestBox1">
        <AverageTestBox testData={average2} />
      </div>
      <div className="AverageTestBox2">
        <AverageTestBox testData={average1} />
      </div>
      {lastTwoResults.length > 0 && (
        <div className="RecentTestBox1">
          <RecentTestBox testData={lastTwoResults[0]} /> {/* Pass the first result */}
        </div>
      )}

      {lastTwoResults.length > 1 && (
        <div className="RecentTestBox2">
          <RecentTestBox testData={lastTwoResults[1]} /> {/* Pass the second result */}
        </div>
      )}
      <SetUpBox />
    </div>
  );


};

export default OverviewPage;