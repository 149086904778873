import React, { useState, useEffect } from 'react';
import './AgriloHistoryPage.css';
import AgriloOverviewBox from '../components/AgriloOverviewBox';
import AgriloTestsBox from '../components/AgriloTestsBox';
import AgriloTestPreviewBox from '../components/AgriloTestPreviewBox';
import AgriloRecommendationsBox from '../components/AgriloRecommendationsBox';

const AgriloHistoryPage = (access) => {
    // const { access } = location.state || {};
    const tests = [
        {
          TestID: 1,
          Name: "Soil Test 1",
          Date: "2024-08-08T12:00:00.000Z",
          Result: 7, 
          Plant: "Barley",
          Chemical: "Nitrate",
          rating: 0,
        },
        {
          TestID: 2,
          Name: "Water Test 1",
          Date: "2024-08-05T10:30:00.000Z",
          Result: 32,
          Plant: "Barley",
          Chemical: "Nitrate",
          rating: 2,
        },
        {
          TestID: 3,
          Name: "Tissue Analysis",
          Date: "2024-08-02T15:45:00.000Z",
          Result: 24,
          Plant: "Barley",
          Chemical: "Nitrate",
          rating: 1,
        },
        {
          TestID: 4,
          Name: "Soil Test 2",
          Date: "2024-07-23T09:15:00.000Z",
          Result: 22,
          Plant: "Barley",
          Chemical: "Nitrate",
          rating: 1,
        },
      ];
      

      const [selectedTest, setSelectedTest] = useState(tests[0]);

    return (
        <div className="Agrilo-HistoryPage">
            <h1 className='Agrilo-HistoryPage-h1'> History</h1>
            <div className='Agrilo-HistoryPage-line1'/>
            <AgriloOverviewBox/>
            <AgriloTestsBox testData={tests}
            selectedTest={selectedTest}
            access={access.access}
            onTestSelect={setSelectedTest}/>
            <AgriloTestPreviewBox testData={selectedTest}/>
            <AgriloRecommendationsBox/>
        </div>
    );
};

export default AgriloHistoryPage;