import React from 'react';
import './AverageTestBox.css';


const AverageTestBox = ({ testData }) => {
    return (
        <div className="AverageTestBox">
            <h1 className='AverageTestBox-h1'>{testData.month} AVERAGE</h1>
            <div className='AverageTestBox-line'></div>
            <div className='AverageTestBox-analyte-box'>
                <h2 className='AverageTestBox-h2 analyte'>CORTISOL</h2>
            </div>
            <div className='AverageTestBox-severity-box'>
                <div className='AverageTestBox-severity-icon'/>
            </div>
            <h2 className='AverageTestBox-h2 average'>AVERAGE</h2>
            <h3 className='AverageTestBox-h3'>{parseFloat(testData.average_test_result).toFixed(2)}</h3>
            <h4 className='AverageTestBox-h4'>ng/mL</h4>
            <h5 className='AverageTestBox-h5 highest'>{parseFloat(testData.highest_test_result).toFixed(2)}</h5>
            <h6 className='AverageTestBox-h6 highest'>ng/mL</h6>
            <h5 className='AverageTestBox-h5 lowest'>{parseFloat(testData.lowest_test_result).toFixed(2)}</h5>
            <h6 className='AverageTestBox-h6 lowest'>ng/mL</h6>
            <h2 className='AverageTestBox-h2 highest'>HIGHEST</h2>
            <h2 className='AverageTestBox-h2 lowest'>LOWEST</h2>
            <h2 className='AverageTestBox-h2 amount'>{testData.test_count} CORTISOL TESTS IN {testData.month}</h2>
        </div>
    );
};

export default AverageTestBox;
