import React from 'react';
import './RecentTestBox.css';
import RecentTestResults from '../components/RecentTestResults';
import RecentTestCircle from './RecentTestCircle';


const RecentTestBox = ({ testData }) => {
    return (
        <div className="RecentTestBox">
            <h1 className='header-text'>RECENT TEST</h1>
            <div className='info-box'>
                <div className='v1'/>
                <h2 className='recent-test-h2 test'>Test</h2>
                <h3 className='recent-test-h3'>A-12 Adr</h3>
                <h2 className='recent-test-h2'>Date</h2>
                <h3 className='recent-test-h3'>{new Date(testData.Date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
                })}</h3>
                <h2 className='recent-test-h2'>Time</h2>
                <h3 className='recent-test-h3'>{new Date(`1970-01-01T${testData.Time}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h3>

                <div className='rectangle'>
                    <h4 className='recent-test-h4'>Cortisol</h4>
                </div>
            </div>
            <RecentTestCircle testData={testData}/>
            <RecentTestResults testData={testData}/>
        </div>
    );
};

export default RecentTestBox;
