import React from 'react';
import './SetUpBox.css';
import AnalytesSelector from './AnalytesSelector';
import SensorSelector from './SensorSelector';


const SetUpBox = () => {
    return (
        <div className="SetUpBox">
            <h1 className='SetUpBox-h1'>SETUP</h1>
            <div className='SetUpBox-line'></div>
            <AnalytesSelector/>
            <SensorSelector/>
        </div>
    );
};

export default SetUpBox;
