import React from 'react';
import './AgriloRecommendationsBox.css';
import RecommendationsIcon from '../assets/agriloIcons/RecommendationsIcon.png';


const AgriloRecommendationsBox = () => {
    return (
        <div className="AgriloRecommendationsBox">
            <img src={RecommendationsIcon} alt="shot1" className="AgriloRecommendationsBox-icon" />
            <h1 className='AgriloRecommendationsBox-h1'>Recommendations</h1>
            <div className='AgriloRecommendationsBox-line1' />
            <div className='AgriloRecommendationsBox-recommendations'>
                <div className='recommendationBox'>
                    <div className='status'>
                        <h5 className='status-tag'>Optimal</h5>
                    </div>
                    <h2 className='recommendation-tag'>Recommendation</h2>
                    <div className='line2'/>
                    <h2 className='title-tag'>Test 12 - Barley</h2>
                    <h3 className='date-tag'>August 8, 2024 </h3>
                    <div className='type1'>
                        <h4 className='type-tag'>Barley</h4>
                    </div>
                    <div className='type2'>
                        <h4 className='type-tag'>Nitrate</h4>
                    </div>
                </div>
            </div>
            <div className='AgriloRecommendationsBox-recommendations'>
                <div className='recommendationBox'>
                    <div className='status2'>
                        <h5 className='status-tag'>High</h5>
                    </div>
                    <h2 className='recommendation-tag'>Recommendation</h2>
                    <div className='line2'/>
                    <h2 className='title-tag'>Test 12 - Barley</h2>
                    <h3 className='date-tag'>August 5, 2024 </h3>
                    <div className='type1'>
                        <h4 className='type-tag'>Barley</h4>
                    </div>
                    <div className='type2'>
                        <h4 className='type-tag'>Nitrate</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgriloRecommendationsBox;