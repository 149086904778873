import React from 'react';
import './RecommendationSelectionBox.css';


const RecommendationSelectionBox = ({ testData }) => {
    const SeverityColor = ['#f54244', '#fce93d', '#47a857', '#fce93d', '#f54244']
    const SeverityRecommendation = ['Low Cortisol', 'Low Cortisol', 'Normal Cortisol', 'Elevated Cortisol', 'Elevated Cortisol']

    if (!testData || Object.keys(testData).length === 0) { // Check if testData is empty
        return (
            <div className="RecommendationSelectionBox">
                <div className="RecommendationSelectionBox-info-box">
                    <p className="no-test-message">No test</p>
                </div>
            </div>
        );
    }

    const explanation = testData.Feedback.split("\n\n")[0];

    const formattedText = testData.Feedback.split('\n').slice(1).map((line, index) => {
        const boldStart = line.indexOf('**');
    const boldEnd = line.indexOf('**', boldStart + 2);
    if (boldStart !== -1 && boldEnd !== -1) {
      const boldText = line.substring(boldStart + 2, boldEnd);
      const normalText = line.substring(boldEnd + 2);
      return (
        <li key={index} style={{ marginBottom: '1em' }}>
          <strong>{boldText}</strong>{normalText}
        </li>
      );
    } else {
      return <p key={index} style={{ marginBottom: '1em' }}>{line}</p>;
    }
      });

    return (
        <div className="RecommendationSelectionBox">
            <h1 className='RecommendationSelectionBox-header-text'>ACTIVE</h1>
            <div className='RecommendationSelectionBox-info-box'>
                <div className='v1' />
                <h2 className='RecommendationSelectionBox-h2 test'>Test</h2>
                <h3 className='RecommendationSelectionBox-h3'>Cortisol Test</h3>
                <h2 className='RecommendationSelectionBox-h2'>Date</h2>
                <h3 className='RecommendationSelectionBox-h3'>{new Date(testData.Date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}</h3>
                <h2 className='RecommendationSelectionBox-h2'>Time</h2>
                <h3 className='RecommendationSelectionBox-h3'>{new Date(`1970-01-01T${testData.Time}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h3>

                <div className='RecommendationSelectionBox-rectangle'>
                    <h4 className='RecommendationSelectionBox-h4'>Cortisol</h4>
                </div>
            </div>

            <div className='RecommendationSelectionBox-status-icon' style={{ borderColor: SeverityColor[testData.TestSeverity - 1] }} />
            <h1 className='RecommendationSelectionBox-result' style={{ color: SeverityColor[testData.TestSeverity - 1] }}>{parseFloat(testData.TestResult).toFixed(2)}</h1>
            <h1 className='RecommendationSelectionBox-result-format' style={{ color: SeverityColor[testData.TestSeverity - 1] }}>ng/mL</h1>
            <div className='RecommendationSelectionBox-status-box' style={{ backgroundColor: SeverityColor[testData.TestSeverity - 1] }}>
                <h4 className='RecommendationSelectionBox-h6' >{SeverityRecommendation[testData.TestSeverity - 1]}</h4>
            </div>

            <div className='RecommendationSelectionBox-recommendation-box'>
                <div className='RecommendationSelectionBox-v2' />
                <h2 className='RecommendationSelectionBox-h5'>Recommendation</h2>
                <h2 className='RecommendationSelectionBox-diagnosis'>{explanation}</h2>
            </div>
            <ol className='RecommendationSelectionBox-recommendations'>{formattedText}</ol>
            
        </div>
    );
};


export default RecommendationSelectionBox;


