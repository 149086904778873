import React, { useState, useEffect } from 'react';
import './RecommendationsPage.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import HistoryBox from '../components/HistoryBox';
import RecommendationSelectionBox from '../components/RecommendationSelectionBox';

const RecommendationPage = (access) => {
    const [selectedTest, setSelectedTest] = useState(null);
    // const { access } = location.state || {};

    const [error, setError] = useState('');
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getTestFilter/', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests);
                    setLoading(false);
                }
            })
            .catch(error => {
                setError('An error occurred');
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (tests.length > 0) {
            setSelectedTest(tests[tests.length - 1]);
        }
    }, [tests]);

    return (
        <div className="RecommendationPage">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    <HistoryBox testData={tests}
                        selectedTest={selectedTest}
                        onTestSelect={setSelectedTest} 
                        title={'RECOMMENDATIONS'}/>
                    <RecommendationSelectionBox testData={selectedTest} />
                </>
            )}
        </div>
    );


};

export default RecommendationPage;