import React, { useState, useEffect } from 'react';
import './AgriloTestPreviewBox.css';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import placeholder from '../assets/agriloIcons/AgriloPlaceholder2.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const AgriloTestPreviewBox = ({ testData }) => {
  const color = ['#CFBF05', '#4C6CFD', '#C83A31']
  const recommendation = ['Low', 'Optimal', 'High']

  return (
    <div className="AgriloTestPreviewBox">
      <img src={TestsIcon} alt="shot1" className="AgriloTestPreviewBox-icon" />
      <h1 className='AgriloTestPreviewBox-h1'>Test Preview</h1>
      <div className='AgriloTestPreviewBox-line1' />
      <h2 className='AgriloTestPreviewBox-h2'>{testData.Name}</h2>
      <h3 className='AgriloTestPreviewBox-h3'>
        {new Date(testData.Date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })} </h3>
      <div className='AgriloTestPreviewBox-placeholder' >
        <div style={{ width: 150, height: 150, position: 'relative'}}>
          <CircularProgressbar strokeWidth={5} value={testData.Result} circleRatio={0.75} maxValue={40} minValue={0} text={testData.Result}
            styles={buildStyles({
              pathColor: color[testData.rating],
              fontWeight: '500',
              fontFamily: 'Poppins',
              textSize: '40px',
              rotation: 1 / 2 + 1 / 8,
              trailColor: "#eee",
              classes: "AgriloTestPreviewBox-value-text",
              textColor: color[testData.rating],
            })} />
        </div>
        <h1 className='AgriloTestPreviewBox-chemical'>Nitrate</h1>
        <h1 className='AgriloTestPreviewBox-metric'>ppm</h1>
        <h1 className='AgriloTestPreviewBox-range-low'>0</h1>
        <h1 className='AgriloTestPreviewBox-range-high'>40</h1>
        <div className='AgriloTestPreviewBox-rating' style={{backgroundColor: color[testData.rating]}}>
          <h1 className='AgriloTestPreviewBox-rating-text'>{recommendation[testData.rating]}</h1>
        </div>
        <h1 className='AgriloTestPreviewBox-range'>10-30ppm</h1>
      </div>
      <h4 className='AgriloTestPreviewBox-h4'>Recommendation</h4>
      <h5 className='AgriloTestPreviewBox-h5'>The nitrate level is sufficient for most crops, supporting healthy growth and development. </h5>
    </div>
  );
};

export default AgriloTestPreviewBox;
