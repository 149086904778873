import React from 'react';
import './AgriloTaskBox.css';
import TasksIcon from '../assets/agriloIcons/TasksIcon.png';

const AgriloTaskBox = ({task}) => {

    const DateConverter = (startDate, endDate) => {
        const start = new Date(startDate + 'T12:00:00Z');
        const end = new Date(endDate + 'T12:00:00Z');

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const startMonth = monthNames[start.getMonth()];
        const startDay = start.getDate();
        const endMonth = monthNames[end.getMonth()];
        const endDay = end.getDate();

        if (start.toDateString() === end.toDateString()) {
            return `${startMonth} ${startDay}`;
        } else if (startMonth === endMonth) {
            return `${startMonth} ${startDay}-${endDay}`;
        } else {
            return `${startMonth} ${startDay}-${endMonth} ${endDay}`;
        }
    }

    return (
        <div className="AgriloTaskBox">
            <img src={TasksIcon} alt="shot1" className="AgriloTaskBox-icon" />
            <h1 className='AgriloTaskBox-h1'> Tasks</h1>
            <div className='AgriloTaskBox-line1' />
            <div className="AgriloTaskBox-item-container">
                <nav className="AgriloTaskBox-items">
                    <ul className='AgriloTaskBox-ul'>
                        {task.slice().map(task => (
                            <div className='AgriloTaskBox-taskboxes'>
                                <input type="radio" className='AgriloTaskBox-radio' />
                                <h1 className='AgriloTaskBox-date'>{DateConverter(task.StartDate, task.EndDate)}</h1>
                                <h1 className='AgriloTaskBox-name'>{task.Name}</h1>
                                <svg className='AgriloTaskBox-dots' xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                                    <circle cx="2" cy="2" r="2" fill="#B4B4B4" />
                                    <circle cx="8" cy="2" r="2" fill="#B4B4B4" />
                                    <circle cx="14" cy="2" r="2" fill="#B4B4B4" />
                                </svg>
                            </div>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default AgriloTaskBox;

