import './SignUpPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';


const SignUpPage = () => {
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Username: '',
    Email: '',
    phone: '',
    HealthID: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };



  const handleSignupBTNClicked = async (e) => {
    if (Object.values(formData).some(value => value === '')) {
      alert("Please fill in all the fields");
    } else {

      e.preventDefault();
      const dataToSend = { ...formData, UserType: 'user' };


      try {
        const response = await fetch('https://www.pimasens.com/api/CreateUser/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        });

        const result = await response.json();

        if (response.ok) {
          setShowForm(false)
        } else {
          if (result.Email) {
            alert("There is already an account with this email");
          }
          else if (result.HealthID) {
            alert("There is already an account with this HealthID");
          }
          else if (result.Username) {
            alert("There is already an account with this Username");
          }
          else {
            alert("Error as occured")
          }
        }
      } catch (err) {
        setError('An error occurred');
        console.error('Network error:', err);
      }
    }
  };


  const lineStyles = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '43%',
    width: '1px',
    backgroundColor: 'white',
    background: 'rgba(255, 255, 255, 0.40)',
  };

  const inputStyles = {
    borderRadius: '10px',
    border: '1px solid #9CA5B6',
    background: 'rgba(231, 231, 231, 0.19)',
    display: 'inline-flex',
    padding: '10px 16px 10px 30px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '171px',
  };






  return (
    <div className='signUp'>
      {showForm && (
        <div className='signUp-box'>
          <img src={LoginLogo} alt="LOGO" className="left-image" />
          <div style={lineStyles} />
          <form className='SignUpPage-form'>
            <img src={LoginBarLogo} alt="LOGOBar" className="SignUpPage-right-image" />
            <h1 className='SignUpPage-welcome-text-signup'>Welcome to PimaSens</h1>
            <label className='SignUpPage-label' htmlFor="FirstName">First Name</label>
            <input className='SignUpPage-input' type="text" id="FirstName" name="fname" value={formData.FirstName} onChange={handleChange} />
            <label className='SignUpPage-label' htmlFor="LastName">Last Name</label>
            <input className='SignUpPage-input' type="text" id="LastName" name="lname" value={formData.LastName} onChange={handleChange} />
            <label className='SignUpPage-label' htmlFor="Username">Username</label>
            <input className='SignUpPage-input' type="text" id="Username" name="lname" value={formData.Username} onChange={handleChange} />
            <label className='SignUpPage-label' htmlFor="Email">Email</label>
            <input className='SignUpPage-input' type="email" id="Email" name="fname" value={formData.Email} onChange={handleChange} />
            <label className='SignUpPage-label' htmlFor="Phone">Phone</label>
            <input className='SignUpPage-input' type="number" id="phone" name="lname" value={formData.Phone} onChange={handleChange} />
            <label className='SignUpPage-label' htmlFor="HealthID">HealthID</label>
            <input className='SignUpPage-input' type="text" id="HealthID" name="fname" value={formData.HealthID} onChange={handleChange} />
            <label className='SignUpPage-label' htmlFor="Password">Password</label>
            <input className='SignUpPage-input' type="password" id="password" name="lname" value={formData.Password} onChange={handleChange} />
            <input className='signup-button' type="submit" value="Sign Up" onClick={handleSignupBTNClicked} />
            <img src={LoginSmallLogo} alt="LOGOSmall" className="SignUpPage-bottom-right-image" />
          </form>
        </div>
      )}
      {!showForm && (
        <div className='signUp-box-email'>
          <h2 className='signUp-h2'>You will receive an e-mail shortly with instructions detailing how to finish your registration.
          Please check your junk mail and allow e-mails from mailgun@pimasens.com</h2>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
