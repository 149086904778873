import React from 'react';
import './AgriloOverviewBox.css';
import AgriloPlaceholder from '../assets/agriloIcons/AgriloPlaceholder.png';
import OverviewIcon from '../assets/agriloIcons/OverviewIcon.png';
import AgriloOverviewDisplay from './AgriloOverviewDisplay';

const AgriloOverviewBox = () => {
    const averages1 = [
        {
            range:"Q3",
            months:"JUL - SEP",
        },
        {
            name: "Nitrate",
            number: 26,
            rangeLow: 10,
            rangeHigh: 30,
            rangeMax: 40
        },
        {
            name: "Phosphorus",
            number: 280,
            rangeLow: 150,
            rangeHigh: 250,
            rangeMax: 350
        },
        {
            name: "Potassium",
            number: 15,
            rangeLow: 10,
            rangeHigh: 30,
            rangeMax: 40
        },
        {
            name: "calcium",
            number: 4,
            rangeLow: 5,
            rangeHigh: 10,
            rangeMax: 15
        },
      ];
      const averages2 = [
        {
            range:"Q2",
            months:"APR - JUN",
        },
        {
            name: "Nitrate",
            number: 45,
            rangeLow: 10,
            rangeHigh: 30,
            rangeMax: 40
        },
        {
            name: "Phosphorus",
            number: 280,
            rangeLow: 150,
            rangeHigh: 250,
            rangeMax: 350
        },
      ];
    return (
        <div className="AgriloOverviewBox">
            <img src={OverviewIcon} alt="shot1" className="AgriloOverviewBox-icon" />
            <h1 className='AgriloOverviewBox-h1'> Overview</h1>
            <div className='AgriloOverviewBox-line1' />
            <div className='AgriloOverviewBox-placeholder'>
            {averages1 && (
                <AgriloOverviewDisplay averages={averages1}/>
            )}
            {averages2 && (
                <AgriloOverviewDisplay averages={averages2}/>
            )}
            </div>
        </div>
    );
};

export default AgriloOverviewBox;
