import React from 'react';
import './RecentTestResults.css';

const RecentTestResults = ({ testData }) => {
    const CircleColor = ['#f54244','#fce93d','#47a857','#fce93d','#f54244']
    return (
        <div className="RecentTestResults">
            <div className='box-1'>
                <h1 className='recent-test-result-h1'>CORTISOL RESULTS</h1>
                <div className='RecentTestResults-severity-box'>
                    <div className='RecentTestResults-severity-icon' style={{
            borderColor: testData && CircleColor[testData.TestSeverity - 1] // Set border color dynamically
          }}/>
                </div>
            </div>
            <div className='box-2'>
                <h2 className='recent-test-result-h2'>{parseFloat(testData.TestResult).toFixed(2)}</h2>
                <h3 className='recent-test-result-h3'>ng/ml</h3>
            </div>
            <div className='box-3'>
                {/*Average level of the day?*/}
            </div>
        </div>
    );
};

export default RecentTestResults;
