import React from 'react';
import './CookiePolicyPage.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const CookiePolicyPage = () => {
    return (
      <div className="CookiePolicyPage">
        <NavBar />
        <div className='CookiePolicyPage-box'>
            <h1 className='CookiePolicyPage-header'>PimaSens Cookie Policy</h1>
            <h2>Effective: November 11, 2024</h2>
            <h3>A cookie (also called a “browser cookie”, “HTML cookie” or similar names) is a small piece of data that is stored on your device to help websites and mobile apps track and remember things about you. Other technologies, including Flash cookies (also called “locally shared objects”), Web beacons (also called “pixels”, “transparent GIFs”, “web bugs” or “action tags”) and other Web storage and identifiers associated with your device, may be used for similar purposes. In this policy, we say “cookies” to refer to all of these technologies.</h3>
            <h3>this Cookie Policy applies to the website <a className='CookiePolicyPage-links' href="/">www.pimasens.com</a>. This policy explains more about how we use cookies. <br/>Please see our <a className='CookiePolicyPage-links' href="/about/privacy-policy">Privacy Policy</a> to learn about how we collect and use information from and about you when you use <a className='CookiePolicyPage-links' href="/">www.pimasens.com</a>.</h3>
            <h2>How We Use Cookies</h2>
            <h3>We use cookies (like many online service providers), to recognize you when you visit our website or applications. As of the current date we do not collect third party cookies.</h3>
            <h2>Authentication</h2>
            <h3>We save your login information using cookies so that you do not need to sign in again when you revisit our website.</h3>
            <h2>Changes to This Policy</h2>
            <h3>We may update this privacy policy from time to time. We will notify you of any significant changes by posting the new policy on this page and updating the date above</h3>
        </div>
        <BottomNav />
    </div>
  );
};

export default CookiePolicyPage;