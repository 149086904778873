import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './AccountEditPage.css';
import axios from 'axios';
import Edit from '../assets/zenroIcons/Edit.png';
const AccountEditPage = ({ access, userInfo }) => {
    // const { access } = location.state || {};
    const [showPopup, setShowPopup] = useState(false);
    const [pickName, setPickName] = useState(false);
    const [pickPassword, setPickPassword] = useState(false);
    const [pickDefault, setPickDefault] = useState(false);
    const [changeText, setChangeText] = useState("");
    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');
    const [error, setError] = useState('');
    const [tests, setTests] = useState([]);
    const navigate = useNavigate();
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5MDU3NDg4LCJpYXQiOjE3MjY0NjU0ODgsImp0aSI6ImM4ZDg5YjA1MmU3YjRhODM4YjcxMTMxYzEwNGE4NzVmIiwiVUlEIjoyfQ.YhosNIX6Uq6W3sOhTwukOXD7mheyB4pvQqaMai0ULw8'


    const handleEditClick = (givenText) => {
        setChangeText(givenText);
        if (givenText == 'password') {
            setPickPassword(true)
        } else if (givenText == 'name') {
            setPickName(true)
        } else {
            setPickDefault(true)
        }
        setShowPopup(true);
    };

    const handleSignOutClick = () => {
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/');
    };

    const handleCloseClick = () => {
        setPickDefault(false)
        setPickName(false)
        setPickPassword(false)
        setShowPopup(false);
    };

    const handleSaveClick = () => {
        let dataToSend = {};
        if (changeText == 'password') {
            dataToSend = { password: value1 };
        } else if (changeText == 'name') {
            dataToSend = { FirstName: value1, LastName: value2 };
            userInfo.FirstName = value1
            userInfo.LastName = value2
        } else if (changeText == 'HealthID') {
            dataToSend = { HealthID: value1 }
            userInfo.HealthID = value1
        } else if (changeText == 'phone') {
            dataToSend = { phone: value1 }
            userInfo.phone = value1
        }

        axios.put('https://www.pimasens.com/api/updateUser/', dataToSend, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
        setPickDefault(false)
        setPickName(false)
        setPickPassword(false)
        setShowPopup(false);
    }

    return (
        <div className="AccountEditPage">
            <div className='account-basic-info-box'>
                <h1 className='AccountEditPage-h1'>Basic Info</h1>
                <div className='AccountEditPage-line1'></div>
                <img src={Edit} alt="" className="edit-icon username" onClick={() => handleEditClick('Username')} />
                <h2 className='AccountEditPage-h2 username'>UserName</h2>
                <h3 className='AccountEditPage-h3 username'>{userInfo.Username}</h3>
                <img src={Edit} alt="" className="edit-icon name" onClick={() => handleEditClick('name')} />
                <h2 className='AccountEditPage-h2 name'>Name</h2>
                <h3 className='AccountEditPage-h3 name'>{userInfo.FirstName + " " + userInfo.LastName}</h3>
            </div>

            <div className='account-info-box'>
                <h1 className='AccountEditPage-h1'>Account Info</h1>
                <div className='AccountEditPage-line1'></div>
                {/* <img src={Edit} alt="" className="edit-email-icon"/> */}
                <h2 className='AccountEditPage-h2 email'>Email</h2>
                <h3 className='AccountEditPage-h3 email'>{userInfo.Email}</h3>
                <img src={Edit} alt="" className="edit-icon password" onClick={() => handleEditClick('password')} />
                <h2 className='AccountEditPage-h2 password'>Password</h2>
                <h3 className='AccountEditPage-h3 password'>••••••••••••</h3>
            </div>

            <div className='account-personal-info-box'>
                <h1 className='AccountEditPage-h1'>Personal Info</h1>
                <div className='AccountEditPage-line1'></div>
                <img src={Edit} alt="" className="edit-icon phone" onClick={() => handleEditClick('phone')} />
                <h2 className='AccountEditPage-h2 phone'>Phone Number</h2>
                <h3 className='AccountEditPage-h3 phone'>{userInfo.phone}</h3>
                <img src={Edit} alt="" className="edit-icon HealthID" onClick={() => handleEditClick('HealthID')} />
                <h2 className='AccountEditPage-h2 HealthID'>HealthID</h2>
                <h3 className='AccountEditPage-h3 HealthID'>{userInfo.HealthID}</h3>
            </div>

            <button className='account-sign-out-button' onClick={()=>handleSignOutClick()}>Sign Out</button>
            {showPopup && (
                <div className="AccountEditPage-popup">
                    <h4 className='AccountEditPage-h4'>Change {changeText}</h4>
                    <button className='AccountEditPage-close-button' onClick={() => handleCloseClick(false)}>X</button>
                    <div className='AccountEditPage-line2'></div>
                    {pickDefault && (
                        <input type="text" className="AccountEditPage-input1" defaultValue={"apples"} onChange={(e) => setValue1(e.target.value)} />
                    )}
                    {pickName && (
                        <form className='AccountEditPage-form'>
                            <label for="fname">First name:</label>
                            <input type="text" className="AccountEditPage-input1" defaultValue={"apples"} onChange={(e) => setValue1(e.target.value)} />
                            <label for="Lname">Last name:</label>
                            <input type="text" className="AccountEditPage-input2" defaultValue={"apples"} onChange={(e) => setValue2(e.target.value)} />
                        </form>
                    )}
                    {pickPassword && (
                        <form className='AccountEditPage-form'>
                            <label for="password1">New Password:</label>
                            <input type="password" className="AccountEditPage-input1" onChange={(e) => setValue1(e.target.value)} />
                            <label for="password2">Repeat Password:</label>
                            <input type="password" className="AccountEditPage-input2" onChange={(e) => setValue2(e.target.value)} />
                        </form>
                    )}
                    <button className='AccountEditPage-save-button' onClick={() => handleSaveClick()}>Save</button>
                </div>
            )}
        </div>
    );


};

export default AccountEditPage;