import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Agrilo.css';
import AgriloNavagatorBar from '../components/AgriloNavagatorBar';
import agriloIconTop from '../assets/agriloIcons/AgriloTop.png';
import AgriloHistoryPage from './AgriloHistoryPage';
import AgriloSummaryPage from './AgriloSummaryPage';

const Agrilo = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('summary');

    return (


        <div className="Agrilo">
            <img src={agriloIconTop} alt="shot1" className="agriloIconTop" />
            <h1 className='Agrilo-h1'>Agrilo</h1>
            <AgriloNavagatorBar onMenuItemSelect={setSelectedMenuItem}/>
            {selectedMenuItem === 'history' && <AgriloHistoryPage/>}
            {selectedMenuItem === 'summary' && <AgriloSummaryPage/>}
        </div>
    );

};



export default Agrilo;