import './ActivateTokenPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ActivateTokenPage = () => {
  const [showForm, setShowForm] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [token, setToken] = useState()
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };



  const handleContinueBTNClicked = async (e) => {
    if (email != '') {
      const queryParams = {
        Email: email,
      };
      axios.post('https://www.pimasens.com/api/getActivateToken/', {
        params: queryParams
      })
        .then(response => {
          const data = response.data;

          if (data.error) {
            setError('An error occurred');
          } else {
            setToken(data)
          }
        })
        .catch((error) => {
          setError('An error occurred');
        });
      setShowForm(false)
    }
  };



  return (
    <div className='ActivateTokenPage'>
      <img src={LoginLogo} alt="LOGO" className="left-image" />
      <div className='ActivateTokenPage-form-box'>
        <h1 className='ActivateTokenPage-header'>Forgot Password</h1>
        {showForm && (
          <form className='ActivateTokenPage-form'>
            <label className='ActivateTokenPage-label' htmlFor="Email">Enter the email address associated with your account and we'll send you a link to activate your password</label>
            <input className='ActivateTokenPage-input' type="email" id="Email" name="email" value={email} onChange={handleEmailChange} />
            <input className='ActivateTokenPage-btn' type="button" value="Resend Authorization" onClick={handleContinueBTNClicked} />
          </form>
        )}
        {!showForm && (
          <h2 className='ActivateTokenPage-h2'>If an account exists for {email}, you will get an email with a link to activate your account. If it doesn't arrive, be sure to check your spam folder.</h2>
        )}
        <img src={LoginSmallLogo} alt="LOGOSmall" className="ActivateTokenPage-image" />
      </div>
    </div>
  );
};

export default ActivateTokenPage;
