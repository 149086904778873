import React, { useState, useEffect } from 'react';
import './HistoryBox.css';
import ZenroAppIcon from '../assets/zenroIcons/ZenroAppIcon.png';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import classNames from "react-day-picker/style.module.css";
import axios from 'axios';
import { jsPDF } from "jspdf";

const HistoryBox = ({ testData, selectedTest, onTestSelect, access, title}) => {
    const [selectedSeverity, setSelectedSeverity] = useState('');
    const [givenTest, setTests] = useState(testData)
    const [range, setRange] = useState({ from: '', to: '' });
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')



    const onReportClick = () => {
        const doc = new jsPDF();
        let currentDay = null;
        let yPos = 10; // Start at the top of the page
      
        testData.forEach(test => {
          if (test.Date !== currentDay) {
            currentDay = test.Date; 
            yPos += 10; // Add some spacing for a new day
          }
      
          const formattedText = `Cortisol TestResult: "${test.TestResult}" Date: "${test.Date}" Time: "${test.Time}"`;
          doc.text(formattedText, 2, yPos); 
          yPos += 10; // Move down for the next line
        });
      
        doc.save("cortisol_test_results.pdf");
    }


    const onResetClick = () => {
        setInputValue('')
        setSelectedSeverity('')
        setFrom('')
        setTo('')
        setRange({from: '', to: '' })
    }

    const handleSeverityChange = (event) => {
        setSelectedSeverity(event.target.value);
    };

    const headers = {
        'Authorization': 'Bearer ' + access
    }

    const onSearchClick = () => {
        if (range.from !== '') {
            setFrom(format(range.from, 'yyyy-MM-dd'))
        }
        if (range.to !== '') {
            setTo(format(range.to, 'yyyy-MM-dd'))
        }
    
        const queryParams = {
            StartDate: from,
            EndDate: to,
            TestSeverity: selectedSeverity,
        };
    


        axios.get('https://www.pimasens.com/api/getTestFilter/', {
            headers: headers,
            params: queryParams
        })
            .then(response => {
                const data = response.data;

                if (data.error == "No tests found") {
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests)
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }

    const handleDayClick = (day) => {
        const newRange = { ...range };
        if (!range.from && !range.to) {
            newRange.from = day;
            newRange.to = null;
            setFrom(format(newRange.from, 'yyyy-MM-dd'))
        } else if (day < range.from && range.to) {
            newRange.from = day;
            setFrom(format(newRange.from, 'yyyy-MM-dd'))
        } else if (day < range.from && !range.to) {

            newRange.to = range.from;
            newRange.from = day;
            setFrom(format(newRange.from, 'yyyy-MM-dd'))
            setTo(format(newRange.to, 'yyyy-MM-dd'))
        } else if (day > range.from && day < range.to) {
            newRange.to = day;
            setTo(format(newRange.to, 'yyyy-MM-dd'))
        } else if (day > range.to) {
            newRange.to = day;
            setTo(format(newRange.to, 'yyyy-MM-dd'))
        } 
        else if (range.to){
            newRange.from = day;
            newRange.to = null;
            setFrom(format(newRange.from, 'yyyy-MM-dd'))
        } else{
            setFrom('')
            setTo('')
            setRange({from: '', to: '' })
        }

        setRange(newRange);
    };

    const handleOKClick = () => {
        setInputValue(formatRange(range));
        setShowPicker(false);
    };

    const formatRange = (range) => {
        if (!range.from) return '';
        if (range.to) {
            return `${format(range.from, 'MM/dd/yyyy')} - ${format(range.to, 'MM/dd/yyyy')}`;
        }
        return `From: ${format(range.from, 'MM/dd/yyyy')}`;
    };


    const SeverityColor = ['#f54244', '#fce93d', '#47a857', '#fce93d', '#f54244']
    const [filterBox, setfilterBox] = useState(false);
    const handleItemClick = (test) => {
        if (selectedTest !== test) { // Only update if a different test is clicked
            onTestSelect(test);
        }
    };

    const handleFilterClick = () => {
        setfilterBox(!filterBox)
    };

    return (
        <div className="HistoryBox">
            <h1 className='HistoryBox-h1'>{title}</h1>
            <div className='HistoryBox-line1'></div>
            {/* options */}
            <div className='HistoryBox-option-box'>
                <button className='HistoryBox-filter-btn' onClick={() => handleFilterClick()}>
                    <img src={ZenroAppIcon} alt="shot1" className="HistoryBox-filter-icon" />
                    FILTER
                </button>
                <button className='HistoryBox-report-btn'onClick={() => onReportClick()}>CREATE REPORT</button>
            </div>

            {filterBox && (
                <div className='HistoryBox-filter-box'>
                    <h5 className='HistoryBox-h5 date'>Date</h5>
                    <h5 className='HistoryBox-h5 severity'>SeverityLevel</h5>
                    <select className='HistoryBox-severity-selector' value={selectedSeverity} onChange={handleSeverityChange}>
                        <option value="">Select Severity:</option>
                        <option value="1">low</option>
                        <option value="2">moderate low</option>
                        <option value="3">average</option>
                        <option value="4">moderate high</option>
                        <option value="5">high</option>
                    </select>
                    <input
                        className='HistoryBox-filter-daytext'
                        type="text"
                        value={inputValue}
                        readOnly
                        onClick={() => setShowPicker(true)}
                    />
                    {showPicker && (
                        <div className='HistoryBox-filter-daypicker'>
                            <DayPicker
                                classNames={classNames}
                                captionLayout="dropdown"
                                selected={range}
                                onDayClick={handleDayClick}
                                mode="range"
                            />
                            <button onClick={handleOKClick}>OK</button>
                        </div>
                    )}
                    <button className='HistoryBox-reset-btn' onClick={() => onResetClick()}>RESET</button>
                    <button className='HistoryBox-search-btn' onClick={() => onSearchClick()}>SEARCH</button>
                </div>
            )}

            {/* labels for test */}
            <div className='HistoryBox-labels'>
                <h2 className='HistoryBox-h2 Status'>Status</h2>
                <div className='HistoryBox-icon Status' />
                <h2 className='HistoryBox-h2 Analyte'>Analyte</h2>
                <div className='HistoryBox-icon Analyte' />
                <h2 className='HistoryBox-h2 Test'>Test</h2>
                <div className='HistoryBox-icon Test' />
                <h2 className='HistoryBox-h2 Date'>Date</h2>
                <div className='HistoryBox-icon Date' />
            </div>

            {/* Container of test */}
            <div className='HistoryBox-line2'></div>
            <div className="HistoryBox-item-container">
                <nav className="HistoryBox-items">
                
                    <ul className='HistoryBox-ul' style={{ height: filterBox ? '470px' : '650px' }}>
                        {givenTest.slice().map(test => (
                            <li
                                key={test.TestID}
                                className={`HistoryBox-test-item ${selectedTest === test ? 'active' : ''}`}
                                onClick={() => handleItemClick(test)}
                            >
                                <span className="HistoryBox-status-box">
                                    <div className='HistoryBox-status-icon' style={{ borderColor: SeverityColor[test.TestSeverity - 1] }} />
                                    <div className='HistoryBox-line3' ></div>
                                    <h1 className='HistoryBox-h3' style={{ color: SeverityColor[test.TestSeverity - 1] }}>{parseFloat(test.TestResult).toFixed(2)}</h1>
                                    <h1 className='HistoryBox-h4' style={{ color: SeverityColor[test.TestSeverity - 1] }}>ng/mL</h1>
                                </span>
                                <span className="HistoryBox-type-box">Cortisol</span>
                                <span className="HistoryBox-item-name">Cortisol Test</span>
                                {/* <span className="HistoryBox-item-name">{test.name}</span> */}
                                <span className="HistoryBox-item-date">{new Date(test.Date).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

// const [selectedTest, setSelectedTest] = useState(null);
// useEffect(() => {
//     if (.length > 0) {
//         setSelectedTest([0]);
//     }
// }, []); // Run this effect only when '' changes

export default HistoryBox;