// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './NavBar.css';
import SmallLogo from '../assets/BlueBackLogo.png'
import SignUpPage from "../pages/SignUpPage";

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const [username, setUsername] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/sign-up');
  };

  const handleUserClick = () => {
    navigate('/zenro');
  };

  useEffect(() => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('access=')) {
        // Extract the cookie value
        var accessToken = cookie.substring('access='.length);
      }
      if (cookie.startsWith('Username=')) {
        // Extract the cookie value
        var username = cookie.substring('Username='.length);
        setUsername(username)
      }
    }
    if (accessToken) {
      setLoggedIn(true)
    }
  }, []);

  return (
    <div className="navbar">
      <Link to="/home">
        <img src={SmallLogo} alt="" className="menu-icon" />
      </Link>
      <div className="nav-items">
        <ul className="nav-options">
          <li className="nav-option-li first">
            <a href="products">Products</a>
          </li>

          <li className="nav-option-li">
            <a href="pricing">Pricing</a>
          </li>

          <li className="nav-option-li">
            <a href="about-us">About</a>
          </li>

          <li className="nav-option-li last">
            <a href="contact-us">Contact</a>
          </li>
        </ul>
      </div>
      {!loggedIn && (
        <div className="nav-buttons">
          <button className="nav-button login" onClick={handleLoginClick}>Log In</button>
          <button className="nav-button signup" onClick={handleSignUpClick}>Sign Up</button>
        </div>
      )}
      {loggedIn && (
        <div className="nav-user">
          <button className="nav-button user" onClick={handleUserClick}>{username}</button>
        </div>
      )}
    </div>
  );
};

export default NavBar;
